@import '../../sass/variables.scss';

.cvSecSearch {
    width: 25rem;
    height: 2.5rem;
    border-radius: 20rem;
    padding: 0 0.8rem;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;

    input {
      width: 90%;
      margin-left: 0.3rem;
      border: none;
      &:focus {
        outline: none;
      }
    }

    .iconSearch{
      width: 9%;
      border: none;
      background: none;
      cursor: pointer;
      margin-bottom: 0.1rem;
      
      &:active {
        transform: scale(0.97);
      }
    }
}
   
