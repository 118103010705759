@import '../../sass/variables.scss';

.cvsecBackground {
   height: 100vh;
   width: 100%;
   background: linear-gradient(0deg, rgba(31,91,88,1) 26%, rgba(31,34,33,1) 83%);
   position: fixed;

   .backgroundImage {
      position: absolute;
      right: 48%;
      top: 20%;

      img {
         filter: contrast(0);
         width: 90rem;
         height: 55rem;
      }

      @media (max-width: $md) {
         display: none;
      }
   }
}
