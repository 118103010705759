@import '../../sass/variables.scss';

.containerLogin {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   width: 100%;
   height: 100vh;

   .formLogin {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 1;
      input {
         width: 100%;
         margin-bottom: 2.2rem;
      }
      .imgLogo {
         display: flex;
         justify-content: center;
         width: 100%;
         margin-bottom: 2.7rem;
         img {
            width: 25rem;
            @media (max-width: $sm) {
               width: 70%;
               margin-bottom: 0rem;
            }   
         }
      }
      .errorContent {
         display: flex;
         justify-content: center;
         margin-bottom: 2rem;
   
         .errorMessageStyle {
            font-size: 1rem;
            color: $warning;
            font-weight: bold;
         }
      }
   }
}
