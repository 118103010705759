@import '../../sass/variables.scss';

.containerVideoStream{
  margin: 1.5rem 0;
  padding-bottom: 3rem;

  @media (max-width: $lg) {
    max-width: 90% !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  @media (max-width: $md) {
    overflow-x: scroll;
  }

  .notRegisters {
    margin-top: 10rem;
  }
}
   
