@import "../../sass/variables.scss";

.customButton {
   border: none;
   border-radius: 0.5rem;
   padding: 0.5rem 1rem;
   background-color: $primary;
   box-shadow: 0 0.2rem 0.2rem $shadow;
   color: $white;
   font-weight: bold;
   transition: 0.1s;
   display: flex;
   justify-content: center;
   align-items: center;
   min-height: 2.5rem;

   &:active {
      box-shadow: 0 0.1rem 0.1rem $shadow;
      transform: scale(0.98);
   }

   &:disabled {
      filter: brightness(0.7);
      cursor: not-allowed;
   }

   .cvsecTooltipButton {
      position: absolute;
      background-color: $white;
      color: $cuaternary;
      padding: 0.4rem 0.6rem;
      border-radius: 0.5rem;
      top: -2.8rem;
      transition: 0.5s;
      min-width: 10rem;
      opacity: 0;

      .arrowTooltip {
         width: 1rem;
         height: 1rem;
         background-color: $white;
         position: absolute;
         bottom: -0.5rem;
         left: 50%;
         transform: translateX(-50%) rotate(45deg);
      }
   }

   &:hover .cvsecTooltipButton {
      opacity: 1;
      top: -3.3rem;
   }
}
