@import '../../sass/variables.scss';

.cvSecModal {

  & > div:nth-child(1) {
    background: linear-gradient(0deg, rgba(31,91,88,1) 26%, rgba(31,34,33,1) 83%);
  }

  .headerModal {
    color: $white;
    border-bottom: transparent;

    button {
      opacity: 1;
      filter: invert(1) brightness(150%); 
    }
  }
  
  .bodyModal {
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
   
  .modalFooter {
    border-top: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .buttonCancel {
      background: $white;
      color: $cuaternary;
    }

    .buttonAccept {
      background: $cuaternary;
      color: $white;
    }
  }
}
   
