@import '../../../../sass/variables.scss';

.containerAddDeviceModal{
  width: 60%;
  
  label {
    color: $white !important;
    font-size: 1.1rem !important;
    font-weight: 600 !important;
    font-family: "Inter", sans-serif !important;
  }

  label::before {
    display: none !important;
  }

  div > span {
    padding: 0.375rem 0.75rem;
    height: 2.5rem;
  }

  div[role="alert"] div {
    color: $warning;
    font-size: 0.9rem;
    font-weight: 600;
    font-family: "Inter", sans-serif;
    margin: 0.2rem 0 0.6rem 0;
  }


  button {
    position: absolute;
    font-size: 1rem;
    font-weight: 600;
    font-family: "Inter", sans-serif;
    box-shadow: 0 0.2rem 0.2rem $shadow;
    height: 2.5rem;
    bottom: -3.5rem;
  }

  button:nth-child(1) {
    left: 50%;
    transform: translateX(-50%);

    &:hover {
      color: $black !important;
      border-color: transparent !important;
    }

    &:active {
      transform: translateX(-50%) scale(0.98) !important;
    }
  }

  button:nth-child(2) {
    position: absolute;
    right: 1.2rem;
    padding: 0.5rem 1rem;
    min-width: 6rem;
    width: auto;

    &:active {
      transform: scale(0.98) !important;
    }
  }


  button span {
    padding: 0 !important;
  }
}
   
