@import '../../../sass/variables.scss';

.titleHeader {
    color: $primary;
    font-weight: bold;
  }
.titleHeader1 {
    color: $primary;
    font-weight: bold;
    text-align: left;
  }
.rowEdit {
  justify-content: center;
  margin-top: 40px;
}
.pReview {
  color: $primary;
  margin-top: 30px;
  font-weight: bold;
  font-size: 30px;
}
.notificationList{
  margin-top:50px ;
}
.notificationItem{
  text-align: left;
}
.notificationTitle{
  color: $primary;

}
.preview{
  margin-left: 40px;
}