@import '../../sass/variables.scss';

.containerNavbar {
  width: 100%;
  color: $white;

    ul {
      width: 100%;
      padding: 1.5rem 0;
      margin: 0;
      list-style: none;
      text-align: center;

      @media (max-width: $lg) {
        text-align: start;
        margin: 0 auto;
        width: 75%;
        column-count: 3;
      }

      @media (max-width: $md) {
        width: 80%;
        column-count: 2;
      }
      
      li {
        display: inline-block;
        cursor: pointer;
        font-weight: 500;
        font-size: 1.4rem;
        margin: 0 1rem;

        @media (max-width: $lg) {
          display: block;
          padding: 0.5rem 0;
        }

        &:hover, &.active {
          text-shadow: 0 0 1rem $white;
        }
      }
  }
}